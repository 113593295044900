import React, { useEffect } from "react";
import { Route, Routes, Outlet, useNavigate, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import axios from "axios";

// import Pages
import "./App.css";
import Login from "./pages/Login/Login";
import Header from "./pages/Header/Header";
import Sidebar from "./pages/Sidebar/Sidebar";
// import Demo from "./pages/Demo_FUTURE/Demo";
import Payments from "./pages/Payments/Payments";
import NewSalesCampaign from "./pages/NewCampaign/NewCampaign";
import VoiceSettings from "./pages/VoiceSettings/VoiceSettings";
import CampaignHistory from "./pages/CampaignHistory/CampaignHistory";

// import Components
import { URL_ROUTES, API_BASE_URL, API_ENDPOINT } from "./utils/constants";
import { readToken, isTokenExpired, logout } from "./services/sessionServices";
import { toastError } from "./components/Toast/Toast";

const Layout = () => {
  const navigate = useNavigate();
  const location = useLocation();

  /* START: check login session for every route change ---------------------------------- */
  const token = readToken();
  useEffect(() => {
    if (!token) {
      navigate(`/${URL_ROUTES.login}`);
    } else {
      if (isTokenExpired()) {
        logout();
        navigate(`/${URL_ROUTES.login}`);
      } else if (location.pathname === "/") {
        navigate(`/${URL_ROUTES.newCampaign}`);
      }
    }
  }, [navigate, location]);
  /* END: check login session for every route change ------------------------------------ */

  /* START: Common API Params ----------------------------------------------------------- */
  const getHeaderPayload = () => {
    return {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
  };
  /* END: Common API Params ------------------------------------------------------------- */

  /* START: get application base data details --------------------------------------------------------- */
  useEffect(() => {
    readToken() && getBaseLangData();
  }, []);

  const getBaseLangData = () => {
    try {
      axios
        .get(`${API_BASE_URL}${API_ENDPOINT.baseLanguageData}`, { headers: getHeaderPayload() })
        .then((response) => {
          if (response?.data?.success) {
            localStorage.setItem("lang_data", JSON.stringify(response.data.data));
          } else {
            toastError("Failed to load Language Data.");
          }
        })
        .catch((error) => {
          toastError("Failed to load Language Data, please reload the load");
        });
    } catch (error) {
      toastError("Failed to load Language Data, please reload the load!");
    }
  };
  /* END: get application base data details ----------------------------------------------------------- */

  /* START: get profile details --------------------------------------------------------- */
  useEffect(() => {
    readToken() && getProfileDetails();
  }, []);

  const getProfileDetails = () => {
    try {
      axios
        .get(`${API_BASE_URL}${API_ENDPOINT.profile}`, { headers: getHeaderPayload() })
        .then((response) => {
          if (response?.data?.success) {
            localStorage.setItem("profile", JSON.stringify(response.data.data));
          } else {
            toastError("Failed to load Profile Data.");
          }
        })
        .catch((error) => {
          toastError("Failed to load Profile Data, please reload the load");
        });
    } catch (error) {
      toastError("Failed to load Profile Data, please reload the load!");
    }
  };
  /* END: get profile details ----------------------------------------------------------- */

  return (
    <div className="layout">
      <Sidebar />
      <div className="main-content">
        <Header />
        <Outlet />
        <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      </div>
    </div>
  );
};

const App = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/" element={<Layout />}>
        <Route path="new-sales-campaign" element={<NewSalesCampaign />} />
        <Route path="campaign-history" element={<CampaignHistory />} />
        <Route path="payments" element={<Payments />} />
        <Route path="voice-settings" element={<VoiceSettings />} />
        {/* <Route path="demo" element={<Demo />} /> */}
      </Route>
    </Routes>
  );
};
export default App;
