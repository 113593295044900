import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import classes from "./NewCampaign.module.css";

import { readSessionData, readProfile, readLanguageData } from "../../services/sessionServices";
import { toastSuccess, toastError } from "../../components/Toast/Toast";
import { API_BASE_URL, API_ENDPOINT, URL_ROUTES } from "../../utils/constants";

const NewSalesCampaign = () => {
  const navigate = useNavigate();

  // read data from common service
  const sessionData = readSessionData();
  const languageData = readLanguageData();
  const profile = readProfile();
  const [creditPoints, setCreditPoints] = useState(profile?.credit_points || 0);

  // Check credit points only on initial load
  useEffect(() => {
    if (creditPoints < 5) {
      setShowDialog(true); // Show dialog if credit points are less than 5 on first load
    }
  }, []); // Empty dependency array means it runs once on mount

  // Handle closing of dialog
  const [showDialog, setShowDialog] = useState(false);
  const handleCloseDialog = () => {
    navigate(`/${URL_ROUTES.campaignHistory}`);
  };

  // read field input values
  const [campignData, setCampignData] = useState({ campaignName: "", companyName: "", companyDetail: "", productName: "", productDetail: "", voiceLang: "", voiceName: "" });
  const handleInputChange = (event) => {
    if (event.target.name === "voiceLang") {
      setCampignData({ ...campignData, [event.target.name]: event.target.value, voiceName: "" });
      filterVoiceDropdown(event.target.value);
    } else {
      setCampignData({ ...campignData, [event.target.name]: event.target.value });
    }
  };

  /* START: handle phone number --------------------------------------------------------- */
  const [phoneNumbers, setPhoneNumbers] = useState([""]);

  const handlePhoneNumberChange = (index, value) => {
    const newPhoneNumbers = [...phoneNumbers];
    newPhoneNumbers[index] = value;
    setPhoneNumbers(newPhoneNumbers);
  };

  const handleAddPhoneNumber = () => {
    const lastPhoneNumber = phoneNumbers[phoneNumbers.length - 1];
    const phoneNumberRegex = /^[0-9]{10}$/;

    // If the user has added at least one phone number, skip the validation
    if (phoneNumbers.length > 1 || (phoneNumbers.length === 1 && lastPhoneNumber.trim() !== "")) {
      if (creditPoints >= 5) {
        setPhoneNumbers([...phoneNumbers, ""]);
        setCreditPoints(creditPoints - 5); // Deduct 5 points
        toastSuccess(`Phone number added. You have ${creditPoints - 5} credit points left.`);
      } else {
        setShowDialog(true); // Show dialog if credit points are less than 5
      }
    } else {
      // If it's the first entry or invalid, validate the first phone number
      if (!phoneNumberRegex.test(lastPhoneNumber)) {
        toastError("Please enter a valid phone number before adding another.");
      } else if (creditPoints >= 5) {
        setPhoneNumbers([...phoneNumbers, ""]);
        setCreditPoints(creditPoints - 5); // Deduct 5 points
        toastSuccess(`Phone number added. You have ${creditPoints - 5} credit points left.`);
      } else {
        setShowDialog(true); // Show dialog if credit points are less than 5
      }
    }
  };
  /* END: handle phone number ----------------------------------------------------------- */

  /* START: handle selling points ------------------------------------------------------- */
  const [sellingPoints, setSellingPoint] = useState([""]);
  const handleAddSellingPoint = () => {
    setSellingPoint([...sellingPoints, ""]);
  };

  const handleSellingPointChange = (index, value) => {
    const newSellingPoint = [...sellingPoints];
    newSellingPoint[index] = value;
    setSellingPoint(newSellingPoint);
  };
  /* END: handle selling points --------------------------------------------------------- */

  /* START: handle questions ------------------------------------------------------------ */
  const [questions, setQuestion] = useState([""]);
  const handleAddQuestion = () => {
    setQuestion([...questions, ""]);
  };

  const handleQuestionChange = (index, value) => {
    const newQuestion = [...questions];
    newQuestion[index] = value;
    setQuestion(newQuestion);
  };
  /* END: handle questions -------------------------------------------------------------- */

  /* START: Voice Language and Voice Name --------------------------------------------------------- */
  const [voiceDropdown, setVoiceDropdown] = useState([]);
  const filterVoiceDropdown = (voiceLang) => {
    const data = Array.isArray(languageData) ? languageData.filter((x) => x.lang_name === voiceLang) : [];
    setVoiceDropdown(Array.isArray(data) && data.length ? data[0].voices : []);
  };

  /* END: Voice Language and Voice Name --------------------------------------------------------- */

  /* START: prepare final api payload --------------------------------------------------- */
  const handleCreateCampaignSubmit = (event) => {
    event.preventDefault();
    // console.log(campignData, "check");

    // Ensure that the user has at least one phone number
    if (phoneNumbers.length < 1 || !phoneNumbers[0]) {
      toastError("You must add at least one phone number.");
      return;
    }

    const payload = {
      campaign_name: campignData.campaignName,
      company_name: campignData.companyName,
      company_detail: campignData.companyDetail,
      product_name: campignData.productName,
      product_detail: campignData.productDetail,
      voice_lang: campignData.voiceLang,
      voice_name: campignData.voiceName,
      is_running: false,
      user_id: sessionData.id,
      questions: questions,
      phone_numbers: phoneNumbers,
      selling_points: sellingPoints,
    };
    // console.log(payload, "final");

    callCreateCampaignAPI(payload);
  };
  /* END: prepare final api payload ----------------------------------------------------- */

  /* START: Call Create Campaign API ---------------------------------------------------- */
  const callCreateCampaignAPI = (payload) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionData.access_token}`,
    };

    try {
      axios
        .post(`${API_BASE_URL}${API_ENDPOINT.createCampaign}`, payload, { headers })
        .then((response) => {
          if (response?.data.success) {
            toastSuccess(response?.data?.message);
            navigate(`/${URL_ROUTES.campaignHistory}`);
          } else {
            const errorMsgs = [];
            if (response?.data?.errors) {
              response.data.errors.forEach((x) => errorMsgs.push(x.message));
              toastError(errorMsgs.join("<br/>"));
            } else {
              toastError("Failed to Create Campaign.");
            }
          }
        })
        .catch((error) => {
          toastError(error.response || error.response?.data?.message || error.response?.data?.detail);
        });
    } catch (e) {
      toastError("Something went wrong, please try again!");
    }
  };
  /* END: Call Create Campaign API ------------------------------------------------------ */

  return (
    <>
      {/* Dialog Popup Code */}
      {showDialog && (
        <div className={classes.dialog_overlay}>
          <div className={classes.dialog_box}>
            <h3>Insufficient Credit Points</h3>
            <p>You have {creditPoints} credit points, but you need at least 5 to start a new campaign.</p>
            <button className="btn btn-primary" onClick={handleCloseDialog}>
              Close
            </button>
          </div>
        </div>
      )}

      {/* Main Form Content */}
      {!showDialog && (
        <form onSubmit={handleCreateCampaignSubmit}>
          <div className={classes.form_container}>
            <div className={classes.column}>
              <div className={classes.section}>
                <div className={classes.form_group}>
                  <label htmlFor="campaignName">Name of the Campaign</label>
                  <input type="text" id="campaignName" name="campaignName" required placeholder="Enter campaign name" onChange={handleInputChange} />
                </div>
              </div>
            </div>
          </div>
          <div className={classes.form_container}>
            <div className={classes.column}>
              {/* Organisation Detail */}
              <div className={classes.section}>
                <h5>Organisation Detail</h5>
                <div className={classes.form_group}>
                  <label htmlFor="companyName">Name of the Company (in short)</label>
                  <input type="text" id="companyName" name="companyName" required placeholder="Enter company name" onChange={handleInputChange} />
                </div>
                <div className={classes.form_group}>
                  <label htmlFor="companyDetail">Detail of the Company</label>
                  <textarea id="companyDetail" name="companyDetail" required placeholder="Enter company details" onChange={handleInputChange}></textarea>
                </div>
              </div>

              {/* Product Detail */}
              <div className={classes.section}>
                <h5>Product Detail</h5>
                <div className={classes.form_group}>
                  <label htmlFor="productName">Name of the Product (in short)</label>
                  <input type="text" id="productName" name="productName" required placeholder="Enter product name" onChange={handleInputChange} />
                </div>
                <div className={classes.form_group}>
                  <label htmlFor="productDetail">Detail of the Product</label>
                  <textarea id="productDetail" name="productDetail" required placeholder="Enter product details" onChange={handleInputChange}></textarea>
                </div>
                <div className={classes.form_group}>
                  <div htmlFor="sellingPoints">Main Selling Points (comma separated) Max 5</div>
                  {sellingPoints.map((sellingPoint, index) => (
                    <div className={classes.form_group} key={index}>
                      <small>
                        <label htmlFor={`phoneNumber${index}`}> Selling Point {index + 1}</label>
                      </small>
                      <input type="text" id="sellingPoint" value={sellingPoint} required placeholder="Enter selling point" onChange={(e) => handleSellingPointChange(index, e.target.value)} />
                    </div>
                  ))}
                  {sellingPoints.length < 5 && (
                    <a className="float-end text-decoration-none" onClick={handleAddSellingPoint}>
                      <small>Add More...</small>
                    </a>
                  )}
                </div>
              </div>
            </div>

            <div className={classes.column}>
              {/* Phone Numbers */}
              <div className={classes.section}>
                <h5>Phone Numbers</h5>
                {phoneNumbers.map((phoneNumber, index) => (
                  <div className={classes.form_group} key={index}>
                    <small>
                      <label htmlFor={`phoneNumber${index}`}>Number {index + 1}</label>
                    </small>
                    <input type="text" id={`phoneNumber${index}`} value={phoneNumber} required onChange={(e) => handlePhoneNumberChange(index, e.target.value)} placeholder="Enter phone number" />
                  </div>
                ))}
                {creditPoints >= 5 && (
                  <a className="float-end text-decoration-none" onClick={handleAddPhoneNumber}>
                    <small>Add More...</small>
                  </a>
                )}
                {creditPoints < 5 && <p className={classes.error_message}>You need at least 5 credit points to add more phone numbers.</p>}
              </div>

              {/* Voice Settings */}
              <div className={classes.section}>
                <h5>Voice Settings</h5>
                <div className={classes.column}>
                  <div className={classes.form_group}>
                    <label htmlFor="voiceLang">Language</label>
                    <select id="voiceLang" name="voiceLang" required onChange={handleInputChange}>
                      <option value="">select...</option>
                      {languageData &&
                        languageData.map((obj, index) => (
                          <option key={index} value={obj.lang_name}>
                            {obj.lang_name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className={classes.column}>
                  <div className={classes.form_group}>
                    <label htmlFor="voiceName">Voice</label>
                    <select id="voiceName" name="voiceName" required onChange={handleInputChange}>
                      <option value="">{campignData.voiceLang ? "select..." : "please select language."}</option>
                      {campignData.voiceLang &&
                        voiceDropdown.map((obj, index) => (
                          <option key={index} value={obj.voice_type}>
                            {obj.voice_type}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className={classes.section}>
                <h5>What information you want from interested customers?</h5>
                <div className={classes.form_group}>
                  {questions.map((question, index) => (
                    <div className={classes.form_group} key={index}>
                      <small>
                        <label htmlFor={`question${index}`}> Question {index + 1}</label>
                      </small>
                      <input type="text" id="question" value={question} required placeholder="Enter question" onChange={(e) => handleQuestionChange(index, e.target.value)} />
                    </div>
                  ))}
                  <a className="float-end text-decoration-none" onClick={handleAddQuestion}>
                    <small>Add More...</small>
                  </a>
                </div>
              </div>
              <div className={classes.section}>
                <button type="submit" className="btn btn-primary">
                  Start Campaign
                </button>
              </div>
            </div>
          </div>
        </form>
      )}
    </>
  );
};

export default NewSalesCampaign;
