import { jwtDecode } from "jwt-decode";
import { toastError } from "../components/Toast/Toast";

/* START: User Session Related ----------------------------------------------------------- */
export const readToken = () => {
  const token = localStorage.getItem("loginData") || null;
  if (token) {
    return JSON.parse(token).access_token;
  }
  return token;
};

export const readSessionData = () => {
  const session = localStorage.getItem("loginData") || null;
  if (session) {
    return JSON.parse(session);
  }
  return session;
};

export const isTokenExpired = () => {
  const token = readToken();
  if (token) {
    const decodedToken = jwtDecode(token);
    // console.log(token, decodedToken, decodedToken.exp * 1000 < Date.now(), decodedToken.exp * 1000, Date.now());
    return decodedToken.exp * 1000 < Date.now();
  } else {
    return true;
  }
};

export const logout = () => {
  localStorage.removeItem("loginData");
  localStorage.removeItem("lang_data");
  localStorage.removeItem("profile");
  toastError("Token has Expired!.");
};

/* END: User Session Related ------------------------------------------------------------ */

/* START: User Profile Related ---------------------------------------------------------- */
export const readProfile = () => {
  const profile = localStorage.getItem("profile") || null;
  if (profile) {
    return JSON.parse(profile);
  }
  return profile;
};
/* END: User Profile Related ------------------------------------------------------------ */

/* START: Base Data Related ------------------------------------------------------------- */
export const readLanguageData = () => {
  const language = localStorage.getItem("lang_data") || [];
  if (language) {
    return Array.isArray(language) ? language : JSON.parse(language);
  }
  return language;
};
/* END: Base Data Related --------------------------------------------------------------- */
