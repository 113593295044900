import React from "react";
import { Link, useLocation } from "react-router-dom";
import classes from "./Sidebar.module.css";
import { URL_ROUTES } from "../../utils/constants";

const Sidebar = () => {
  const location = useLocation(); // Get the current route
  // console.log(location);
  const isActive = (path) => location.pathname === path; // Function to determine if the link is active

  return (
    <nav className={classes.sidebar}>
      <ul>
        <li className={isActive(`/${URL_ROUTES.newCampaign}`) ? classes.active : ""}>
          <Link to="/new-sales-campaign">New Campaign</Link>
        </li>
        <li className={isActive(`/${URL_ROUTES.campaignHistory}`) ? classes.active : ""}>
          <Link to="/campaign-history">Campaign History</Link>
        </li>
        <li className={isActive(`/${URL_ROUTES.payment}`) ? classes.active : ""}>
          <Link to="/payments">Payments</Link>
        </li>
        <li className={isActive(`/${URL_ROUTES.voiceSettings}`) ? classes.active : ""}>
          <Link to="/voice-settings">Voice Settings</Link>
        </li>
        {/* <li className={isActive(`/${URL_ROUTES.demo}`) ? classes.active : ""}>
          <Link to="/demo">Demo</Link>
        </li> */}
      </ul>
    </nav>
  );
};

export default Sidebar;
