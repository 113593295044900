import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const toastSuccess = (msg) => {
  toast.success(msg);
};
export const toastInfo = (msg) => {
  toast.info(msg);
};

export const toastError = (msg) => {
  toast.error(msg, { autoClose: 6000 });
};

export const toastClearAll = () => {
  toast.dismiss();
};
