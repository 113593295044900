import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { URL_ROUTES } from "../../utils/constants";
import { isTokenExpired, logout, readProfile } from "../../services/sessionServices";
import "./Header.css";

const Header = () => {
  const profile = readProfile();

  const navigate = useNavigate();
  useEffect(() => {
    if (isTokenExpired()) {
      navigate(`/${URL_ROUTES.login}`);
    }
  }, []);

  const handleLogout = () => {
    logout();
    navigate(`/${URL_ROUTES.login}`);
  };

  return (
    <header className="header">
      <div className="header-left">Smart Call</div>
      <div className="header-right">
        <button>Credits: {profile?.credit_points}</button>
        <button onClick={handleLogout}>Logout</button>
      </div>
    </header>
  );
};

export default Header;
