import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import { FaUser, FaLock, FaEnvelope, FaPhone } from "react-icons/fa";

import classes from "./Login.module.css";
import { toastSuccess, toastError } from "../../components/Toast/Toast";
import { API_BASE_URL, API_ENDPOINT, URL_ROUTES } from "../../utils/constants";
import { isTokenExpired } from "../../services/sessionServices";

const Login = () => {
  const navigate = useNavigate();

  /* START: check login session and redirect to newCampaign page if session is valid ----------- */
  useEffect(() => {
    if (!isTokenExpired()) {
      navigate(`/${URL_ROUTES.newCampaign}`);
    }
  }, []);
  /* END: check login session and redirect to newCampaign page if session is valid ------------- */

  /* START: navigate between login and register form ------------------------------------ */
  const [action, setAction] = useState("");
  const registerLink = () => {
    setAction(" " + classes.active);
  };
  const loginLink = () => {
    setAction("");
  };
  /* END: navigate between login and register form -------------------------------------- */

  /* START: Login Operations ------------------------------------------------------------ */
  const [loginData, setLoginData] = useState({ email: "", password: "" });
  const handleLoginChange = (event) => {
    setLoginData({ ...loginData, [event.target.name]: event.target.value });
  };

  const handleLoginResponse = (input) => {
    if (input.data?.success) {
      localStorage.setItem("loginData", JSON.stringify(input.data.data));
      navigate(`/${URL_ROUTES.newCampaign}`);
    } else {
      toastError("Failed to Login.");
    }
  };

  const handleLogin = (event) => {
    callLoginAPI();
    event.preventDefault();
  };

  const callLoginAPI = () => {
    try {
      axios
        .post(`${API_BASE_URL}${API_ENDPOINT.login}`, loginData)
        .then((response) => handleLoginResponse(response))
        .catch((error) => {
          toastError(error.response.data?.message || error.response.data?.detail);
          // console.log(error);
        });
    } catch (error) {
      toastError("Something went wrong, please try again!");
    }
  };
  /* END: Login Operations -------------------------------------------------------------- */

  /* START: Registration Operations ----------------------------------------------------- */
  const [registerData, setRegisterData] = useState({
    name: "",
    mobile: "",
    email: "",
    role_id: 1,
    password: "",
  });

  const handleRegisterChange = (event) => {
    setRegisterData({
      ...registerData,
      [event.target.name]: event.target.value,
    });
  };

  const handleRegister = (event) => {
    callRegisterAPI();
    event.preventDefault();
  };

  const callRegisterAPI = () => {
    try {
      axios
        .post(`${API_BASE_URL}${API_ENDPOINT.register}`, registerData)
        .then((response) => {
          if (!response.data.success) {
            const errorMsgs = [];
            if (response?.data?.errors) {
              response.data.errors.forEach((x) => errorMsgs.push(x.message));
              toastError(errorMsgs.join("<br/>"));
            }
          } else {
            toastSuccess(response.data.message);
            setAction("");
          }
        })
        .catch((error) => {
          // if (error) toastError(error.response?.data?.message || error.response?.data?.detail);
          const errorMsgs = [];
          if (error.response?.data?.errors) {
            error.response.data.errors.forEach((x) => errorMsgs.push(x.message));
          }
          toastError(errorMsgs.join("<br/>"));
        });
    } catch (error) {
      toastError("Something went wrong, please try again!");
    }
  };
  /* END: Registration Operations ------------------------------------------------------- */

  return (
    <div className={classes.login_container}>
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

      <div className={`${classes.wrapper}${action}`}>
        {/* Login Form */}
        <div className={`${classes.form_box} ${classes.login}`}>
          <form onSubmit={handleLogin}>
            <h1>Login</h1>
            <div className={classes.input_box}>
              <input type="text" placeholder="Email" name="email" required onChange={handleLoginChange} />
              <FaUser className={classes.icon} />
            </div>
            <div className={classes.input_box}>
              <input type="passwrod" placeholder="Password" name="password" required onChange={handleLoginChange} />
              <FaLock className={classes.icon} />
            </div>

            <div className={classes.remember_forget}>
              <label>
                {" "}
                <input type="checkbox" />
                Remember me
              </label>
              <a href="#">Forgot Password?</a>
            </div>

            <button type="submit">Login</button>

            <div className={classes.register_link}>
              <p>
                Don't have an account?{" "}
                <a href="#" onClick={registerLink}>
                  Register
                </a>
              </p>
            </div>
          </form>
        </div>

        {/* Register Form */}
        <div className={`${classes.form_box} ${classes.register}`}>
          <form onSubmit={handleRegister}>
            <h1>Registration</h1>
            <div className={classes.input_box}>
              <input type="text" placeholder="Name" name="name" required onChange={handleRegisterChange} />
              <FaUser className={classes.icon} />
            </div>
            <div className={classes.input_box}>
              <input type="text" placeholder="Mobile" name="mobile" required onChange={handleRegisterChange} />
              <FaPhone className={classes.icon} />
            </div>
            <div className={classes.input_box}>
              <input type="email" placeholder="Email" name="email" required onChange={handleRegisterChange} />
              <FaEnvelope className={classes.icon} />
            </div>
            <div className={classes.input_box}>
              <input type="password" placeholder="Password" name="password" required onChange={handleRegisterChange} />
              <FaLock className={classes.icon} />
            </div>

            <div className={classes.remember_forget}>
              <label>
                {" "}
                <input type="checkbox" />I agree to the terms & conditions
              </label>
            </div>

            <button type="submit">Register</button>

            <div className={classes.register_link}>
              <p>
                Already have an account?{" "}
                <a href="#" onClick={loginLink}>
                  Login
                </a>
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
